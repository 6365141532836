/* You can add global styles to this file, and also import other style files */
@import 'assets/theme.scss';

/*
 * Base structure
 */
html {
  position: relative;
  min-height: 100%;
}

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 3.5rem;
  margin-bottom: 60px; /*for sticky footer*/
}

/*
   * Typography
   */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid white;
}
