/*Global Styles*/
* {
  box-sizing: border-box;
  font-family: 'FF Din', 'Trebuchet MS', Arial, 'primeicons', sans-serif !important;
}

body {
  overflow-x: hidden;
  font-size: 13px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'FS Joey', 'Montserrat', sans-serif !important;
}

.mobile {
  display: none;
}

.layout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 60px;
  width: 100%;
  text-align: center;
  background-color: rgb(0, 51, 160);
}

/*Header Styles*/
#header-menu-button {
  width: 60px;
  height: 60px;
  line-height: 60px;
  float: left;
  color: white;
}
#header-menu-button:hover {
  background-color: #326ddf;
}
#header-menu-button .arrow {
  transition: all 0.3s;
  transition-duration: ease-in-out;
}
.header-menu-active .arrow {
  transform: rotate(180deg);
}
.header-menu-active .app-content-wrapper {
  margin-left: 200px;
}

@media (max-width: 1024px) {
  .header-menu-active .app-content-wrapper {
    margin-left: 0px;
  }
}

.topbar-profile {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border: 0 none;
  margin-right: 6px;
  margin-bottom: 40px;
  margin-left: 10px;
}

#topbar-profile-menu-button {
  float: right;
  top: 0;
  height: 60px;
  padding: 20px;
  font-weight: 500;
}

#topbar-profile-menu-button span#topbar-name {
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  margin-right: 10px;
  color: white;
  display: block;
}

#topbar-profile-menu-button i {
  vertical-align: middle;
  color: white;
  margin-bottom: 40px;
}

#topbar-profile-menu-button:hover {
  background-color: #326ddf;
}

#topbar-last-access {
  font-size: 10px;
  color: white;
}

#topbar-name-container {
  display: inline-block;
}

#topbar-usermenu {
  background-color: white;
  list-style: none;
  width: 300px;
  right: 0;
  top: 60px;
  left: auto;
  padding: 10px;
  margin: 0;
  position: fixed;
  display: none;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

ul.usermenu-active {
  display: block !important;
}

#topbar-usermenu li a {
  display: block;
  text-align: left;
  color: #333333;
  padding: 10px 20px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid transparent;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

#topbar-usermenu li a:hover {
  border-color: #2162b0;
}

#topbar-usermenu li a i {
  margin-right: 9px;
  vertical-align: middle;
}

.topbar-name-mobile {
  display: none;
  text-align: justify;
  border-bottom: 1px solid;
  margin-bottom: 5px;
}

.topbar-name-mobile span {
  display: block;
}

@media (max-width: 400px) {
  #topbar-name-container {
    display: none;
  }

  .topbar-name-mobile {
    display: block;
  }
}

/*Content*/
.body-wrapper {
  padding-left: 0;
  overflow-x: hidden;
}

.app-content-wrapper {
  padding: 15px 0px;
  -webkit-transition: margin-left 0.3s;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}

/*Sidebar*/
.sidebar {
  z-index: 999;
  position: fixed;
  height: 100%;
  margin-left: -200px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.12);
  width: 200px;

  -webkit-transition: margin-left 0.3s;
  -moz-transition: margin-left 0.3s;
  -o-transition: margin-left 0.3s;
  transition: margin-left 0.3s;
}

.sidebar-root {
  width: 200px;
  margin: 0;
  padding: 0 10px;
  list-style: none;
}

.header-menu-active .sidebar {
  margin-left: 0;
}

/*Sidebar menu*/
.sidebar-submenu {
  list-style: none;
  padding: 5px 0px;
  overflow: hidden;
  list-style-type: none;
  margin: 0;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.nav-link {
  padding: 5px 10px;
  color: #535353;
  position: relative;
  display: block;
  text-decoration: none;
  border: 1px solid transparent;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.nav-link i {
  margin-right: 5px;
}

a.nav-link span {
  color: #535353;
}

.nav-link i.fa-angle-down {
  transition: all 0.3s;
  transition-duration: ease-in-out;
}
.active-item > a > i.fa-angle-down,
.active-subitem > a > i.fa-angle-down {
  transform: rotate(180deg);
}

.sidebar .sidebar-title {
  color: rgb(0, 51, 160);
  letter-spacing: 0.5px;
  padding: 25px 0 10px 0;
  font-size: 12px;
  border-bottom: 1px solid rgb(0, 51, 160);
  margin-left: 6px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.sidebar-menuitem-toggler {
  float: right;
}

.sidebar-root .nav-item a:hover {
  border-color: #2162b0;
}

.sidebar-root .active-item > a {
  background-color: rgb(0, 51, 160);
  color: white;
}
.sidebar-root .active-item > a * {
  color: white;
}
.sidebar-root .active-item .active-subitem > * {
  color: rgb(0, 51, 160);
}

.pd-10 {
  padding-left: 10px;
}
.pd-20 {
  padding-left: 20px;
}
.pd-30 {
  padding-left: 30px;
}
.pd-40 {
  padding-left: 40px;
}
.pd-50 {
  padding-left: 50px;
}

/*footer*/
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 10px;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: white;
}

/*animations*/
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

/*sura fonts*/
@font-face {
  font-family: 'FS Joey';
  src: url('/assets/fonts/FS Joey/FS_Joey.otf') format('opentype');
}
@font-face {
  font-family: 'FF Din';
  src: url('/assets/fonts/FF Din/DIN.ttf') format('opentype');
}
